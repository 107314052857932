import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import style from './Navbar.module.css';
import logo from '../images/logo.png';
import dealers from '../icons/dealers.png';
import remodelers from '../icons/remodelers.png';
import contractors from '../icons/contractors.png';
import manufactures from '../icons/manufactures.png';
import retailers from '../icons/retailers.png';
import distributors from '../icons/distributors.png';
import omni from '../icons/omni.png';
import estimate from '../icons/estimate.png';
import buildoptimize from '../icons/buildoptimize.png';
import drafting from '../icons/drafting.png';
import homeconfigure from '../icons/homeconfigure.png';
import planlibrary from '../icons/planlibrary.png';
import bldr from '../icons/bldr.png';
import render from '../icons/render.png';
import vendo from '../icons/vendo.png';
import view from '../icons/view.png';
import nexus from '../icons/nexus.png';

export default function Navbar() {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebaropen = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <>
    <header>
      <Link to="/" className={style.logo}><img src={logo} alt='Archism'></img></Link>
      <ul className={style.navlist}>
        <li id={style.desktop}><Link to="/">HOME</Link></li>
        <li className="nav-item dropdown" id={style.desktop}>
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">SOLUTIONS</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href='' className="dropdown-item">
                    <div className={style.solutionsdd}>
                      <img src={dealers}></img>
                      <div>
                        <h6>Dealers</h6>
                        <p>win more bids with faster estimates and more accurate takeoffs</p>
                      </div>
                    </div>
                    </a>
                    <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/omni' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/estimate' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={estimate}></img>
                         <h6>Archism Estimate</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                   <div className={style.solutionsdd}>
                       <img src={remodelers}></img>
                       <div>
                        <h6>Homebuilders/Remodelers</h6>
                        <p>Estimating and drafting solutions for design/build remodelers</p>
                       </div>
                     </div>
                   </a>
                   <ul class="dropdown-menu submenu" id={style.submenu}>

                      <li><Link to="/Buildoptimize" className="dropdown-item">
                      <div className={style.solutionsdd}>
                        <img src={buildoptimize}></img>
                        <div>
                          <h6>Build Optimize</h6>
                          <p>Prevent Construction Conflicts</p>
                       </div>
                     </div>
                        </Link>
                      </li>

                      <li><Link to="/Drafting" className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={drafting}></img>
                          <div>
                            <h6>Drafting</h6>
                            <p>Expert Drafting Services</p>
                          </div>
                        </div>
                        </Link>
                      </li>

                      <li><a href='' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={homeconfigure}></img>
                          <div>
                            <h6>Home Configure</h6>
                            <p>Configurable Visualization</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><a href='https://archismdreamhomes.com/' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={planlibrary}></img>
                          <div>
                            <h6>Home Plan Library</h6>
                            <p>Ready-to-build home plans<br/>that include turnkey marketing</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><a href='' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={bldr}></img>
                          <div>
                            <h6>My Home BLDR</h6>
                            <p>The Fastest path from plan<br/>subbmissionn to recieving</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><Link to="/Render" className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={render} id={style.render}></img>
                          <h6>Render</h6>
                        </div>
                        </Link>
                      </li>

                    </ul>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                   <div className={style.solutionsdd}>
                       <img src={contractors}></img>
                       <div>
                        <h6>Home Improvement Contractors</h6>
                        <p>Creating a superior in-home selling experience</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/vendo' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/view' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={view}></img>
                         <h6>Archism View</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                   <div className={style.solutionsdd}>
                       <img src={manufactures}></img>
                       <div>
                        <h6>Manufactures</h6>
                        <p>Erp & quoting technology for window and dooor manufacturers</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to="/omni" className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/vendo' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/nexus' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={nexus}></img>
                         <h6>Archism Nexus</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                   <div className={style.solutionsdd}>
                       <img src={retailers}></img>
                       <div>
                        <h6>Retailers</h6>
                        <p>CPQ technology that power the world's premier retailers</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to="/omni" className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/vendo' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>
                    </ul>
                  </li>

                  <li><a href='' className="dropdown-item">
                   <div className={style.solutionsdd}>
                       <img src={distributors}></img>
                       <div>
                        <h6>Wholesale Distributors</h6>
                        <p>Boost sales and streamline production</p>
                       </div>
                     </div>
                   </a>
                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to="/omni" className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
            </ul>
        </li>

        <li className="nav-item dropdown" id={style.desktop}>
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">INDUSTRIES</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={dealers}></img>
                      <h6>Dealers</h6>
                    </div>
                    </a>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={manufactures}></img>
                      <h6>Door & Window<br/>Manufacturers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={remodelers}></img>
                      <h6>Homebuilders/Remodelers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={contractors}></img>
                      <h6>Home Improvement<br/>Contractors</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={retailers}></img>
                      <h6>Retailers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={distributors}></img>
                      <h6>Wholesale Distributors</h6>
                    </div>
                    </a>
                  </li>
                  
            </ul>
        </li>

        <li className="nav-item dropdown" id={style.desktop}>
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">RESOURCES</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href='' className="dropdown-item">
                    <div>
                      <h6>Customer Success</h6>
                      <p>Archism Professional Services</p>
                    </div>
                    </a>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                    <div>
                      <h6>Knowledge Base & Training</h6>
                      <p>product & Training Guide for Customers</p>
                    </div>
                    </a>
                  </li>   
            </ul>
        </li>
        <li id={style.desktop}><Link to="/AboutUs">ABOUT US</Link></li>
        <li id={style.desktop}><Link to="/JoinUs">JOIN US</Link></li>
      </ul>


      {/*mobile navbar*/}

      <ul className={sidebarVisible ? `${style.sidebar} ${style.open}` : style.sidebar}>
        <li><Link to="/" onClick={toggleSidebaropen}>HOME</Link></li>
        <li className="nav-item dropdown">
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">SOLUTIONS</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                    <div className={style.solutionsdd}>
                      <img src={dealers}></img>
                      <div>
                        <h6>Dealers</h6>
                        <p>win more bids with faster estimates and more accurate takeoffs</p>
                      </div>
                    </div>
                    </a>
                    <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/omni' className="dropdown-item"  onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/estimate' className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={estimate}></img>
                         <h6>Archism Estimate</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                   <div className={style.solutionsdd}>
                       <img src={remodelers}></img>
                       <div>
                        <h6>Homebuilders/Remodelers</h6>
                        <p>Estimating and drafting solutions for design/build remodelers</p>
                       </div>
                     </div>
                   </a>
                   <ul class="dropdown-menu submenu" id={style.submenu}>

                      <li><Link to="/Buildoptimize" className="dropdown-item" onClick={toggleSidebaropen}>
                      <div className={style.solutionsdd}>
                        <img src={buildoptimize}></img>
                        <div>
                          <h6>Build Optimize</h6>
                          <p>Prevent Construction Conflicts</p>
                       </div>
                     </div>
                        </Link>
                      </li>

                      <li><Link to="/Drafting" className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.solutionsdd}>
                          <img src={drafting}></img>
                          <div>
                            <h6>Drafting</h6>
                            <p>Expert Drafting Services</p>
                          </div>
                        </div>
                        </Link>
                      </li>

                      <li><a href='' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={homeconfigure}></img>
                          <div>
                            <h6>Home Configure</h6>
                            <p>Configurable Visualization</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><a href='' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={planlibrary}></img>
                          <div>
                            <h6>Home Plan Library</h6>
                            <p>Ready-to-build home plans<br/>that include turnkey marketing</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><a href='' className="dropdown-item">
                       <div className={style.solutionsdd}>
                          <img src={bldr}></img>
                          <div>
                            <h6>My Home BLDR</h6>
                            <p>The Fastest path from plan<br/>subbmissionn to recieving</p>
                          </div>
                        </div>
                        </a>
                      </li>

                      <li><Link to="/Render" className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.solutionsdd}>
                          <img src={render} id={style.render}></img>
                          <h6>Render</h6>
                        </div>
                        </Link>
                      </li>

                    </ul>
                  </li>
                  
                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                   <div className={style.solutionsdd}>
                       <img src={contractors}></img>
                       <div>
                        <h6>Home Improvement Contractors</h6>
                        <p>Creating a superior in-home selling experience</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/vendo' className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>

                    <li><a href='' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={view}></img>
                         <h6>Archism View</h6>
                       </div>
                      </a>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                   <div className={style.solutionsdd}>
                       <img src={manufactures}></img>
                       <div>
                        <h6>Manufactures</h6>
                        <p>Erp & quoting technology for window and dooor manufacturers</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/omni' className="dropdown-item"  onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/vendo' className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>

                    <li><a href='' className="dropdown-item">
                       <div className={style.mydiv}>
                         <img src={nexus}></img>
                         <h6>Archism Nexus</h6>
                       </div>
                      </a>
                    </li>
                      
                    </ul>
                  </li>
                  
                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                   <div className={style.solutionsdd}>
                       <img src={retailers}></img>
                       <div>
                        <h6>Retailers</h6>
                        <p>CPQ technology that power the world's premier retailers</p>
                       </div>
                     </div>
                   </a>

                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/omni' className="dropdown-item"  onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>

                    <li><Link to='/vendo' className="dropdown-item" onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={vendo}></img>
                         <h6>Archism Vendo</h6>
                       </div>
                      </Link>
                    </li>
                    </ul>
                  </li>

                  <li><a href={() => false} className="dropdown-item" onClick={e => e.stopPropagation()}>
                   <div className={style.solutionsdd}>
                       <img src={distributors}></img>
                       <div>
                        <h6>Wholesale Distributors</h6>
                        <p>Boost sales and streamline production</p>
                       </div>
                     </div>
                   </a>
                   <ul class="dropdown-menu submenu" id={style.submenu}>
                    <li><Link to='/omni' className="dropdown-item"  onClick={toggleSidebaropen}>
                       <div className={style.mydiv}>
                         <img src={omni}></img>
                         <h6>Archism omni</h6>
                       </div>
                      </Link>
                    </li>
                      
                    </ul>
                  </li>
                  
            </ul>
        </li>

        <li className="nav-item dropdown">
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">INDUSTRIES</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={dealers}></img>
                      <h6>Dealers</h6>
                    </div>
                    </a>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={manufactures}></img>
                      <h6>Door & Window<br/>Manufacturers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item" onClick={e => e.stopPropagation()}>
                    <div className={style.mydiv}>
                      <img src={remodelers}></img>
                      <h6>Homebuilders/Remodelers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={contractors}></img>
                      <h6>Home Improvement<br/>Contractors</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={retailers}></img>
                      <h6>Retailers</h6>
                    </div>
                    </a>
                  </li>

                  <li><a href='' className="dropdown-item">
                    <div className={style.mydiv}>
                      <img src={distributors}></img>
                      <h6>Wholesale Distributors</h6>
                    </div>
                    </a>
                  </li>
                  
            </ul>
        </li>

        <li className="nav-item dropdown">
          <a href='' className="nav-link dropdown-toggle" id={style.toogle} data-bs-toggle="dropdown">RESOURCES</a>
            <ul className="dropdown-menu" id={style.dropdown_menu}>
                  <li><a href='' className="dropdown-item">
                    <div>
                      <h6>Customer Success</h6>
                      <p>Archism Professional Services</p>
                    </div>
                    </a>
                  </li>
                  
                  <li><a href='' className="dropdown-item">
                    <div>
                      <h6>Knowledge Base & Training</h6>
                      <p>product & Training Guide for Customers</p>
                    </div>
                    </a>
                  </li>   
            </ul>
        </li>
        <li><Link to="/AboutUs" onClick={toggleSidebaropen}>ABOUT US</Link></li>
        <li><Link to="/JoinUs" onClick={toggleSidebaropen}>JOIN US</Link></li>
        <div id={style.phonebtn} className={style.navright}>
         <Link onClick={toggleSidebaropen} to='/SignUp'><button id={style.signupbtn}>Sign Up</button></Link>
         <Link onClick={toggleSidebaropen} to="/ContactUs"><button id={style.demobtn}>Schedule Demo</button></Link>
        </div>
      </ul>
      <div className={style.navright}>
        <Link to="/SignUp"><button id={style.signupbtn} className={style.tbbtn}>Sign Up</button></Link>
        <Link to="/ContactUs"><button id={style.demobtn} className={style.tbbtn}>Schedule Demo</button></Link>
        <li className={`${style.menu_icon} ${style.hh}`} onClick={toggleSidebaropen}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg></a></li>
      </div>
    </header>
    </>
  )
}
