import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import style from './ContactUs.module.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


export default function ContactUs() {

  const [formdata, setFormdata] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    businessLocation: "",
    businessType: "",
    email: "",
    phone: "",
    query: "",
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://contactform-1-zyx2.onrender.com/api/form', formdata);
      console.log(response);
      console.log(formdata);
      setFormdata({
        firstName: "",
        lastName: "",
        businessName: "",
        businessLocation: "",
        businessType: "",
        email: "",
        phone: "",
        query: "",
      });

      await validationSchema.validate(formdata,{abortEarly: false});
      
      alert("Form submitted successfully!");

    } catch (error) {
      console.error(error);
      
      const newError= {};

      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      })

      setErrors(newError)
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const validationSchema = Yup.object({
    firstName:Yup.string().required("First Name is Required"),
    lastName:Yup.string().required("Last Name is Required"),
    businessName:Yup.string().required("Business Name is Required"),
    businessLocation:Yup.string().required("Business Location is Required"),
    businessType:Yup.string().required("Business Type is Required"),
    email:Yup.string().email("Invalid Email Format").required("Email is Required"),
    phone:Yup.string().matches(/^\d{10}$/, "Phone Number Must Be 10 Digits").required("Phone Number is Required")
  })

  const[errors, setErrors] = useState({});

  return (
    <>
    <div className={style.bannerContainer}>
        <h1>Contact Us</h1>
    </div>

    <div className={style.form_body}>
    <div className={style.container}>
    <form onSubmit={handleSubmit}>
        <div className={style.details}>
          <div className={style.inputbox}>
          <input
              type="text"
              id="fname"
              name="firstName"
              placeholder="First Name"
              value={formdata.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <div className={style.errors}> {errors.firstName}</div>}
          </div>

          <div className={style.inputbox}>
          <input
              type="text"
              id="lname"
              name="lastName"
              placeholder="Last Name"
              value={formdata.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <div className={style.errors}> {errors.lastName}</div>}
          </div>

          <div className={style.inputarea}>
          <input
              type="text"
              id="bname"
              name="businessName"
              placeholder="Business Name"
              value={formdata.businessName}
              onChange={handleChange}
              required
            />
            {errors.businessName && <div className={style.errors}> {errors.businessName}</div>}
          </div>

          <div className={style.inputbox}>
          <input
              type="text"
              id="blocation"
              name="businessLocation"
              placeholder="Business Location"
              value={formdata.businessLocation}
              onChange={handleChange}
              required
            />
            {errors.businessLocation && <div className={style.errors}> {errors.businessLocation}</div>}
          </div>

          <div className={style.inputbox}>
          <input
              type="text"
              id="btype"
              name="businessType"
              placeholder="Business Type"
              value={formdata.businessType}
              onChange={handleChange}
              required
            />
            {errors.businessType && <div className={style.errors}> {errors.businessType}</div>}
          </div>

          <div className={style.inputbox}>
          <input
              type="email"
              id="email"
              name="email"
              placeholder="Business Email"
              value={formdata.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className={style.errors}> {errors.email}</div>}
          </div>

          <div className={style.inputbox}>
          <input
              type="tel"
              id="bphone"
              name="phone"
              placeholder="Business Phone"
              value={formdata.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <div className={style.errors}> {errors.phone}</div>}
          </div>

          <div className={style.inputarea}>
          <textarea
              id="query"
              name="query"
              placeholder="Enter Your query Here..."
              rows="4"
              value={formdata.query}
              onChange={handleChange}
            ></textarea>
            {errors.query && <div className={style.errors}> {errors.query}</div>}
          </div>
        </div>
        <div className={style.sumbitbutton}>
          <button type="submit">Sumbit query</button>
        </div>
      </form>
    </div>
    </div>
    <div className={style.location_container}>
      <div className={style.location}>
      <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.2838773108483!2d75.87546527600182!3d22.717687727635827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd3d958eff61%3A0xd767eff1c39b37f0!2sTrade%20House%2C%20S%20Tukoganj%20Rd%2C%20Manorama%20Ganj%2C%20Kanchan%20Bagh%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1712308330434!5m2!1sen!2sin"
            width="1920"
            height="480"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className={style.location} 
        ></iframe>
      </div>
    </div>
    <div className={style.contactdetails}>
      <div className={style.address}>
        <div className={style.mydiv}></div>
        <div>
          <h4>COMPANY ADDRESS<br/>
          <p>407, Trade House,<br/>
          No. 14/3, S.<br/>
          Tukoganj Rd, Indore</p></h4>
        </div>
      </div>
      <div className={style.phonenum}>
        <FontAwesomeIcon className={style.phoneIcon} icon={faSquarePhone} />
        <div>
        <h4>COMPANY PHONES<br/>
          <p>+91-7023864339</p></h4>
        </div>

      </div>
      <div className={style.phonenum}>
        <FontAwesomeIcon className={style.phoneIcon} icon={faEnvelope} />
        <div>
        <h4>EMAIL<br/>
          <p>Info@myarchism.com</p></h4>
        </div>
      </div>
    </div>
    </>
  )
}
