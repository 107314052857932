import React, { useState } from 'react';
import style from './Nexus.module.css';
import { ReactComponent as Check } from '../images/check.svg';
import phonebanner from '../images/nexus.png';
import nexusicon1 from '../images/nexusicon1.svg';
import nexusicon2 from '../images/nexusicon2.svg';
import nexusicon3 from '../images/nexusicon3.svg';
import pteam from '../images/pteam.png';
import itteam from '../images/itteam.png';
import mteam from '../images/mteam.png';

export default function Nexus() {
    const [activeChannel, setActiveChannel] = useState(null);

    const handleChannelClick = (channel) => {
      setActiveChannel(channel);
    };
  return (
    <>
    <div className={style.container}>
        <div className={style.nexusoverlay}>
        </div>
        <div className={style.containertext}>
            <h1>ERP Software</h1>
            <p>Archism Nexus is a fully integrated enterprise resource planning software (ERP software) for manufacturers in the fenestration industry.</p>
            <button>Learn more</button>
        </div>
    </div>
    <div id={style.phonebanner}>
      <img alt='banner' src={phonebanner}></img>
    </div>

    <div className={style.container2}>
        <div className={style.container2text}>
            <h2>Your Key to Operations Excellence</h2>
            <p>Archism Nexus builds on Archism Omni, our quoting & selling solution, to provide a seamlessly integrated solution for managing your manufacturing operations. See how we helped Vector Window and Doors achieve its vision.</p>
        </div>
        <div className={style.container2text2}>
            <div className={style.textframe}>
                <img alt='icon' src={nexusicon1}></img>
                <h3>Optimize Processes</h3>
                <p>Let our MES technology do the heavy lifting. Leave spreadsheets behind and focus on the things that can’t be automated – like growing your business.</p>
            </div>

            <div className={style.textframe}>
                <img alt='icon' src={nexusicon2}></img>
                <h3>Reduce Costs</h3>
                <p>Make your business more profitable and competitive by maximizing your production and delivery performance, reducing errors, and minimizing waste. </p>
            </div>

            <div className={style.textframe}>
                <img alt='icon' src={nexusicon3}></img>
                <h3>Improve Decision- Making</h3>
                <p>Make informed decisions using statistics, key performance indicators, and dashboard reports. Evaluate performance of your processes in real time. </p>
            </div>
        </div>
    </div>


    <div className={style.selling}>
    <div className={style.selling_section}>
      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('Production')}>Production Scheduling</button>
      {activeChannel === 'Production' && (
        <div>
          <p><Check/> Streamline manufacturing throughout and balance across multiple departments & production lines.</p>
          <p><Check/> Ensure on-time, sequenced production to maximize efficiency. Manage order changes, remakes, and backorders seamlessly.</p>
          <p><Check/> View inventory and capacity dependencies in real time.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('Purchasing')}>Purchasing and<br/>Inventory Management</button>
      {activeChannel === 'Purchasing' && (
        <div>
          <p><Check/> Right-size inventory levels based on actual usage and trends.</p>
          <p><Check/> Track vendor performance over time and influence buying decisions.</p>
          <p><Check/> Purchase special order items in line with manufacturing requirements.</p>
          <p><Check/> Automate routine purchases and set approval thresholds.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('Manufacturing')}>Manufacturing</button>
      {activeChannel === 'Manufacturing' && (
        <div>
          <p><Check/> Run paperless or paper-driven shop floor.</p>
          <p><Check/> Manage and balance capacity across multiple facilities.</p>
          <p><Check/> Track everything with barcode scanning and touch screens.</p>
          <p><Check/> Mobile-friendly technology available for shop personnel.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('Invoicing')}>Invoicing</button>
      {activeChannel === 'Invoicing' && (
        <div>
          <p><Check/> Generate and deliver invoices automatically upon delivery.</p>
          <p><Check/> Delivery invoices according to each customer’s billing preferences (email, EDI, and more).</p>
          <p><Check/> Seamlessly integrate to your Accounts Receivable package of choice—no re-keying.</p>
        </div>
      )}
      </div>
    </div>

    <div className={style.sellingtext}>
        <h2>From <strong>Production</strong><br/><strong>Scheduling</strong> to Invoicing,<br/>We’ve Got You Covered</h2>
      </div>
    </div>

    <div className={style.teamsection}>
        <h1>One Solution with Benefits for Your Whole Team</h1>
        <div className={style.team}>
            <div className={style.departments}> 
                <div className={style.nexusoverlay}></div>
                <img alt='production team' src={pteam}></img>
                <div className={style.departmentstext}>
                    <h3>Production Team</h3>
                    <p>Improve plant operations by eliminating double entry, speeding up the manufacturing process, and reducing inventory levels. Archism helps ensure you’ll produce exactly what’s ordered and deliver it on time.</p>
                </div>
            </div>

            <div className={style.departments}> 
                <div className={style.nexusoverlay}></div>
                <img alt='it team' src={itteam}></img>
                <div className={style.departmentstext}>
                    <h3>IT Team</h3>
                    <p>Simplify your tech stack and eliminate the headaches of customizing a generic ERP solution. Functionality designed for the building industry comes standard with Archism Nexus, making setup and maintenance easier. </p>
                </div>
            </div>

            <div className={style.departments}> 
                <div className={style.nexusoverlay}></div>
                <img alt='Management team' src={mteam}></img>
                <div className={style.departmentstext}>
                    <h3>Management Team</h3>
                    <p>Increase profits by seamlessly integrating sales, purchasing, inventory, production, and delivery to optimize your business processes. Gain valuable insights and make smarter decisions using Archism Nexus reporting. </p>
                </div>
            </div>

        </div>
    </div>


    </>
  )
}
