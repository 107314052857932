import React from 'react';
import style from './Drafting.module.css';
import image1 from '../images/drafting3.png';
import image2 from '../images/drafting4.png';
import blueprint from '../images/blueprint.png';
import elevations from '../images/elevations.png';
import floorplans from '../images/floorplans.png';
import roofplans from '../images/roofplans.png';
import crosssection from '../images/crosssection.png';
import planmodify from '../images/planmodify.png';
import siteplan from '../images/siteplan.png';
import dwg from '../images/dwg.png';


export default function Drafting() {
  return (
    <>
    <div className={style.container}>
      <div className={style.containertext}>
        <h1 id={style.text1}>DR<span>AFTING</span></h1>
        <h1>&</h1>
        <h1 id={style.text2}>ESTI<span>MATION</span></h1>
        <p>Our experts will turn your buyer's vision of home into a reality</p>
      </div>
      
      <div className={style.containertext2}>
        <p>Imagine if you had a whole product devlopment team with the technology and expertise to handle any drafting challenge. Drafting makes that possible, turning your concepts into buildable blueprints. From new master sets to site-specific plans or even modifications</p>
      </div>
    </div>

    <div className={style.container2}>
      <div className={style.Drafting}>

        <div id={style.mydiv}>
          <img src={image1}></img>
        </div>

        <div className={style.ditems}>
          <div id={style.mydiv1}>
            <h3>DRAFTING THAT MEASURES UP</h3>
          </div>
          <div id={style.mydiv2}>
            <p>Count on a Drafting team with the technology and building industry expertise to handle your plan creation, whether you need a single plan or go-to solution for expanding your architectural drafting capabilities.</p>
          </div>
        </div>
      </div>

      <div className={style.Drafting} id={style.Drafting}>
        <div className={style.ditems2}>
          <div id={style.mydiv3}>
            <h3>CREATE OR MODIFY HOME PLANS</h3>
          </div>
          <div id={style.mydiv4}>
            <p>Digitally driven drafting allows you to focus on your core competencies and leave the finer details for talented experts with the technology and time to turn your concepts into construction documents.<br/> <br/> Quick turnaround and an ongoing commitment to quality from a team whose only focus is prviding plans based on whatever you and your buyers can imagine.</p>
          </div>
        </div>

        <div id={style.mydiv5}>
          <img src={image2}></img>
        </div>

      </div>
    </div>

    <div className={style.services}>
      <div></div>
      <h2>OUR SERVICES</h2>
      <div></div>
    </div>

    <div className={style.services_menu}>
      <div>
        <img src={blueprint}></img>
        <div id={style.textback}>
          <h6>BLUEPRINT<br/>DRAFTING</h6>
        </div>
      </div>

      <div>
      <img src={elevations}></img>
        <div id={style.textback}>
          <h6>ELEVATIONS</h6>
        </div>
      </div>
      
      <div>
      <img src={floorplans}></img>
        <div id={style.textback}>
          <h6>FLOOR<br/>PLANS</h6>
        </div>
      </div>

      <div>
      <img src={roofplans}></img>
        <div id={style.textback}>
          <h6>ROOF<br/>PLANS</h6>
        </div>
      </div>
    </div>

    <div className={style.services_menu}>
      <div>
        <img src={crosssection}></img>
        <div id={style.textback}>
          <h6>CROSS<br/>SECTION</h6>
        </div>
      </div>

      <div>
      <img src={planmodify}></img>
        <div id={style.textback}>
          <h6>PLAN<br/>MODIFY</h6>
        </div>
      </div>
      
      <div>
      <img src={siteplan}></img>
        <div id={style.textback}>
          <h6>SITE<br/>PLAN</h6>
        </div>
      </div>

      <div>
      <img src={dwg}></img>
        <div id={style.textback}>
          <h6>DWG/<br/>DXF FILES</h6>
        </div>
      </div>
    </div>
    </>
  );
}
