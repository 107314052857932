import React from 'react';
import style from './SignUp.module.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SignUp() {
  return (
    <>
    <div className={style.container}>
        <Link to="/"><FontAwesomeIcon id={style.icon} icon={faTimes} /></Link> 
        <div className={style.signup_window}>
            <h6>WELCOME</h6>
            <h3>Log In to Your Account</h3>
            <label for="email">Email</label>
            <input type="email" placeholder="Please Enter Your Email Here"></input>
            <label for="password">Password</label>
            <input type="password" placeholder="Please Enter Your Password"></input>
            <div className={style.rememberMe}>
              <input type="checkbox" name="rememberMe"/>
              <label for="rememberMe">Remember Me</label>
              <a href=''>Forgot Password?</a>
            </div>
            <button>Continue</button>

            <div className={style.or}>
              <div></div>
              <h6>Or</h6>
              <div></div>
            </div>
            
            <div className={style.newuser}>
              <h6>New User?</h6>
              <a href='#'>SIGN UP HERE</a>
            </div>
        </div>
    </div>
    </>
  )
}
