import React from 'react';
import style from './Estimate.module.css';
import { Link } from 'react-router-dom';
import omni from '../images/omni.png';
import icon1 from '../images/estimateicon4.svg';
import icon2 from '../images/estimateicon3.svg';
import icon3 from '../images/estimateicon2.svg';
import icon4 from '../images/estimateicon1.svg';



export default function Estimate() {
  return (
    <>
    <div className={style.Container}> 
      <div className={style.overlay}></div> 
      <Link id={style.btndesktop} to="/ContactUs"><button>GET A QUOTE TODAY</button></Link>
      <div className={style.Containertext}>
          <h1>Construction Takeoff<br/>and Estimating Services</h1>
          <p>Archism Estimate construction takeoff services delivers<br/>fast and more accurate material takeoffs. It’s convenient<br/>takeoff technology that saves time, manages complexity,<br/>and differentiates your business.</p>
          <a id={style.btnphone} to="/ContactUs"><button>GET A QUOTE TODAY</button></a>  
      </div>
    </div>
    <div id={style.btnphone}>
        <img src={omni} alt='banner'></img>
    </div>

    <div className={style.Container2}>
        <h1>Construction Takeoff Made Easy — and Fast</h1>
        <h6>Archism Estimate uses artificial intelligence technology to help examine blueprints and generate an itemized material takeoff list. The list features the type, size, quantity, and application of construction materials require for an accurate bid.</h6>
        <div className={style.keyspec} id={style.box1}>
            <img alt='icon' src={icon1}></img>
            <div id={style.vline}></div>
            <div className={style.keyspectext}>
                <h4>Incr<span>ease Customer Satisfaction</span></h4>
                <p>Faster, more accurate construction takeoff services that your customers can depend on.</p>
            </div>
        </div>

        <div className={style.keyspec} id={style.box2}>
            <img alt='icon' src={icon2}></img>
            <div id={style.vline}></div>
            <div className={style.keyspectext}>
                <h4>Im<span>prove Accuracy</span></h4>
                <p>Reduce errors and rework with the combination of automated takeoff technology and construction expertise.</p>
            </div>
        </div>

        <div className={style.keyspec} id={style.box3}>
            <img alt='icon' src={icon3}></img>
            <div id={style.vline}></div>
            <div className={style.keyspectext}>
                <h4>Add V<span>alue to Your Business</span></h4>
                <p>Spend less time on bid preparation and free your salespeople to win more business.</p>
            </div>
        </div>

        <div className={style.keyspec} id={style.box4}>
            <img alt='icon' src={icon4}></img>
            <div id={style.vline}></div>
            <div className={style.keyspectext}>
                <h4>Red<span>uce Extra Deliveries</span></h4>
                <p>Archism Estimate allows you to customize and optimize your material lists, ensuring your takeoffs are accurate every time.</p>
            </div>
        </div>
    </div>

    <div className={style.banner} style={{ backgroundImage: `url(${require('../images/estimate1.png')})` }}>
        <div className={style.bannertext}>
            <h1>Fa<span>ster Bidding</span></h1>
            <p><span>The Speed to Sell More.</span> It takes the speed of Archism Estimate takeoff service to keep up with your business. We know that faster takeoffs give you a better chance of winning the project. With Archism Estimate, all you do is submit your blueprint, and we handle the rest – so you can bid more jobs and free up your team to spend more time selling.</p>
        </div>
        <span id={style.estimateoverlay}></span>
    </div>

    <div className={style.banner} style={{ backgroundImage: `url(${require('../images/estimate2.png')})` }}>
        <div className={style.bannertext}>
            <h1>Tec<span>hnology-Based</span></h1>
            <p><span>A Fast and More Accurate Combination.</span> Our experienced estimators use artificial intelligence to complete material takeoffs in just a fraction of the time that it would take someone to complete them manually. And advanced measurement functionality verifies dimensions and flags discrepancies, so your quotes are right the first time.</p>
        </div>
        <span id={style.estimateoverlay}></span>
    </div>

    <div className={style.banner} style={{ backgroundImage: `url(${require('../images/estimate3.png')})` }}>
        <div className={style.bannertext}>
            <h1>Cu<span>stomizable</span></h1>
            <p><span>Tailored Takeoffs That Fit Your Business</span> The way you do material takeoffs is important. Our rules engine allows us to control how your takeoff comes back precisely how you need it. Whether you’re adjusting waste factors for specific materials or customizing takeoff parameters for a particular market or customer, we can handle it. Our onboarding and implementation specialists will help dial in the takeoff logic so that the output you get back matches what you’re used to seeing – quickly and consistently.</p>
        </div>
        <span id={style.estimateoverlay}></span>
    </div>

    <div className={style.banner} id={style.banner4} style={{ backgroundImage: `url(${require('../images/estimate4.png')})` }}>
        <div className={style.bannertext}>
            <h1>POS In<span>tegration Ready</span></h1>
            <p><span>Integrated Technology to Streamline Selling.</span> Archism Estimate supports SKU mapping to connect your blueprints to specific products as well as direct integrations to your point of sale system. Simply upload a plan, we’ll send back a material takeoff, and with the click of a button, your point of sale system can generate a quote –no re-keying necessary.</p>
        </div>
        <span id={style.estimateoverlay}></span>
    </div>
    </>
  )
}
