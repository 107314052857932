import React from 'react';
import style from './Render.module.css'; 
import { ReactComponent as Flooricon } from '../images/flooricon.svg';
import { ReactComponent as Elevationicon } from '../images/elevationicon.svg';
import { ReactComponent as Reel } from '../images/reel.svg';
import { ReactComponent as Toursicon } from '../images/toursicon.svg';
import { ReactComponent as Virctualicon } from '../images/virctualicon.svg';
import { ReactComponent as Rendersicon } from '../images/rendersicon.svg';
import render5 from '../images/render5.png';

export default function Render() {
  return (
    <>
    <div className={style.container}>
      <div className={style.overlay}>
        <div>
          <h1>REALISTIC RENDERINGS<br/>THAT BRING HOMES TO LIFE</h1>
          <p>Sell more homes and attract more buyers with realistic visuals that<br/>bring dream homes to life. With the power of Render, you can turn 2D<br/>house plans into high-fidelity, 3D renderings and interactive videos that<br/>let you virtually walk customers through every room of their new home<br/>with stunning clarity. This not only helps you market your business and<br/>attract more homebuyers, but it also helps you manage expectations<br/>before—not after—you start building.</p>
        </div>
      </div>
    </div>

    <div className={style.container2}>
      <h1>RENDERINGS THAT MAKE IT REAL</h1>
      <p>Exceptional renderings that defy the imagination by displaying your homes in<br/>unimaginable detail. Brought to you by the company that combines unmatched<br/>construction know-how, inspiring visualization technology, and graphic artistry<br/>that’s changing how homes are built and sold.</p>

      <div className={style.Renders}>
        <div id={style.item}>
          <Flooricon className={style.svgicon} />
          <h4>Floor Plan</h4>
        </div>

        <div id={style.item}>
          <Reel className={style.svgicon} />
          <h4>3D Videos</h4>
        </div>

        <div id={style.item}>
          <Elevationicon className={style.svgicon} />
          <h4>Elevations</h4>
        </div>
      </div>

      <div className={style.Renders}>
        <div id={style.item}>
          <Toursicon className={style.svgicon} />
          <h4>Community<br/>Tours</h4>
        </div>

        <div id={style.item}>
          <Virctualicon className={style.svgicon} />
          <h4>3D Virtual<br/>Tours</h4>
        </div>

        <div id={style.item}>
          <Rendersicon className={style.svgicon} />
          <h4>Custom<br/>Renders</h4>
        </div>
      </div>
    </div>
    <div className={style.container3} style={{ backgroundImage: `url(${require('../images/render2.png')})` }}>
      <div className={style.textsection}>
        <h1>VIRTUALLY AMAZING</h1>
        <p>Engage your buyers with sunning<br/>video and virtual tour experiences<br/>that make your homes come to life<br/>with photorealistic quality.</p>
        <ul>
          <li>Floor plan overlays and flyout 'hotspots' give<br/>home buyers a more immersive walkthrough<br/>experiences.</li>
          <li>Choose from ready-to-use quality design<br/>selections or provide our designers with your<br/>own 'fit and finish' selections.</li>
          <li>Showcase each room of your plan fully<br/>furnished for a complete representation of<br/>how the space will look and feel once<br/>it's built.</li>
        </ul>
      </div>
    </div>

    <div className={style.container3} style={{ backgroundImage: `url(${require('../images/render3.png')})` }}>
      <div className={style.textsection2}>
        <h1>3D IMAGERY</h1>
        <p>Impress your customers with 3D<br/>exterior elevations and images<br/>that jump off the page.</p>
        <ul>
          <li>Display your home in photorealistic detail,<br/>complete with geographically relevant<br/>settings and the ability to choose day or<br/>nighttime views.</li>
          <li>3D Interior images showcase furnished views<br/>in photorealistic quality, turning your<br/>construction documents into lifelike scenes<br/>that leave nothing to the imagination.</li>
          <li>3D floor plans reveal realistic models of each<br/>level of the home, including details such as<br/>layout, door swings, finish materials, and<br/>options for furnished or unfurnished views.</li>
        </ul>
      </div>
    </div>
    
    <div className={style.container4} style={{ backgroundImage: `url(${require('../images/render4.png')})` }}>
      <div className={style.imageoverlay}>
        <div>
          <img src={render5} alt='image'></img>
        </div>

        <div className={style.container4text}>
          <h1>2D FLOOR PLANS</h1>
          <p>Create accurate and detailed 2D floor<br/>plans of your home designs and proudly<br/>showcase your layouts to buyers.</p>
          <ul>
            <li>Full-color or black and white floor plans offer a bird’s-<br/>eye view of plans with dimensions, ceiling details,<br/>door swings, and select furnishings (if requested).</li>
            <li>2D floor plans are available for both site-solved<br/>plans (no options) or for master sets showing both<br/>structural and non-structural plan options</li>
          </ul>
        </div>
      </div>
    </div>

    <div className={style.container4} style={{ backgroundImage: `url(${require('../images/render6.png')})` }}>
      <div className={style.imageoverlay}>
        <div className={style.container5text}>
          <h1>MORE THAN PRETTY PICTURES</h1>
          <p>Our Render design team has the construction expertise to create stunning<br/>and accurate renderings that are more than artistic interpretations.</p>
          <ul>
            <li>Renderings are accurately created to scale from a proprietary 3D modeling process.</li>
            <li>Visualize every room with full staging capability.</li>
            <li>Highlight design hot spots, cross sections and construction details.</li>
            <li>Customize lighting, design details, marketing text, and more.</li>
            <li>Purchase individual Render products, or bundle packages for even more value.</li>
          </ul>
        </div>
      </div>
    </div>
    <div id={style.ediv}></div>


    
    </>
  )
}