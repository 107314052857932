import React from 'react';
import style from './View.module.css';
import phonebanner from '../images/view.png';
import banner3 from '../images/view3.png';
import viewicon from '../images/viewicon.svg';

export default function View() {
  return (
    <>
    <div className={style.container}>
    <div className={style.containertext}>
        <h1>Home Visualization Software</h1>
        <p>Allow homeowners to shop and choose from manufacturer catalogs containing the most up-to-date products and current pricing. Archism View integrates with e-commerce solutions, allowing you to set up shopping cart and checkout options for product selections.</p>
      </div>
      <div id={style.vendooverlay}></div>
    </div>
    <div id={style.phonebanner}>
      <img alt='banner' src={phonebanner}></img>
    </div>

    <div className={style.container2}>
      <div className={style.container2text}>
        <h3>Picture, Price, and</h3>
        <h1>Pu<u>rchase</u></h1>
        <p>Provide homeowners with an instant, real-life view of your building products before they buy. Allow dealers and remodelers to accurately display, configure, estimate, and sell directly from your catalog in Archism Omni. Save time, increase viable leads, and elevate customer satisfaction.</p>
      </div>
    </div>

    <div className={style.container3}>
      <img src={banner3} alt="banner"></img>
      <div className={style.container3text}>
        <div className={style.c3items}> 
          <div>
            <h1>Automatically Identify Building Products</h1>
          </div>
          <p>Archism View home visualization software automatically determines where windows, doors, garage doors, and other building products are located. It quickly transforms a home into a configurable photo, so your buyer can visualize and price your products on their home.</p>
        </div>

        <div className={style.c3items}> 
          <div>
            <h1>Configure and Visualize with Your Catalog</h1>
          </div>
          <p>Allow homeowners to shop and choose from manufacturer catalogs containing the most up-to-date products and current pricing. Archism View integrates with e-commerce solutions, allowing you to set up shopping cart and checkout options for product selections.</p>
        </div>

        <div className={style.c3items}> 
          <div>
            <h1>High Tech, Yet Simple to Use</h1>
          </div>
          <p>Simple convenient interface lets homeowners or contractors use a smartphone to upload a photo of a home. Archisn View does the rest.</p>
        </div>
      </div>
    </div>

    <div className={style.container4}>
      <h1>Take the Guesswork out of Configuring and Estimating</h1>
      <div className={style.c4items}>
        <div>
          <h3>Seeing is Believing</h3>
          <p>Convenient, real-time visualization of products on images of homeowner's actual houses generates confidence in configuration and product choices.</p>
          <img src={viewicon} alt="icon"></img>
        </div>

        <div>
          <h3>Make Improved Decisions</h3>
          <p>Simplify product selection. Give sales teams and their homeowner customers the ability to see building products on a real house.</p>
          <img src={viewicon} alt="icon"></img>
        </div>

        <div>
          <h3>Make it Fast and Easy!</h3>
          <p>Give homeowners and pros a simple and fun tool that allows them to visualize options before ordering.</p>
          <img src={viewicon} alt="icon"></img>
        </div>
      </div>
    </div>
    </>
  )
}
