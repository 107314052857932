import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Homepage from './components/Homepage';
import ContactUs from './components/ContactUs';
import Footerpage from './components/Footerpage';
import AboutUs from './components/AboutUs';
import Buildoptimize from './components/solutions/Buildoptimize';
import JoinUs from './components/JoinUs';
import Navbar from './components/Navbar';
import Drafting from './components/solutions/Drafting';
import SignUp from './components/SignUp';
import Render from './components/solutions/Render';
import Omni from './components/solutions/Omni';
import Estimate from './components/solutions/Estimate';
import Vendo from './components/solutions/Vendo';
import Nexus from './components/solutions/Nexus';
import View from './components/solutions/View';

function App() {
    const location = useLocation();
    const hideNavbarFooterRoutes = ['/SignUp']; 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const shouldHideNavbarFooter = hideNavbarFooterRoutes.includes(location.pathname);

    return (
        <>
            {!shouldHideNavbarFooter && <Navbar />}
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/ContactUs" element={<ContactUs />} />
                <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/Buildoptimize" element={<Buildoptimize />} />
                <Route path="/Drafting" element={<Drafting />} />
                <Route path="/JoinUs" element={<JoinUs />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/Render" element={<Render />} />
                <Route path="/omni" element={<Omni />} />
                <Route path="/estimate" element={<Estimate />} />
                <Route path="/vendo" element={<Vendo />}/>
                <Route path='/nexus' element={<Nexus />} />
                <Route path='/view' element={<View />} />
            </Routes>
            {!shouldHideNavbarFooter && <Footerpage />}
        </>
    );
}

export default App;
