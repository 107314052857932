import React from 'react';
import style from './Vendo.module.css';
import phonebanner from '../images/vendo.png';
import { ReactComponent as AWNINGS } from '../images/AWNINGS.svg';
import { ReactComponent as BATHROOM } from '../images/BATHROOM.svg';
import { ReactComponent as CONCRETE } from '../images/CONCRETE.svg';
import { ReactComponent as DECKS } from '../images/DECKS.svg';
import { ReactComponent as DOORS } from '../images/DOORS.svg';
import { ReactComponent as FENCING } from '../images/FENCING.svg';
import { ReactComponent as FLOORING } from '../images/FLOORING.svg';
import { ReactComponent as GARAGE } from '../images/GARAGE.svg';
import { ReactComponent as GUTTER } from '../images/GUTTER.svg';
import { ReactComponent as HVAC } from '../images/HVAC.svg';
import { ReactComponent as KITCHEN } from '../images/KITCHEN.svg';
import { ReactComponent as PATIO } from '../images/PATIO.svg';
import { ReactComponent as ROOFING } from '../images/ROOFING.svg';
import { ReactComponent as SIDING } from '../images/SIDING.svg';
import { ReactComponent as SOLAR } from '../images/SOLAR.svg';
import { ReactComponent as SUNROOMS } from '../images/SUNROOMS.svg';
import { ReactComponent as WINDOWS } from '../images/WINDOWS.svg';

export default function Vendo() {
  return (
    <>
    <div className={style.container}>
      <div className={style.containertext}>
        <h1>Home Improvement Contractor Sales App</h1>
        <p>Archism Vendo is a digital selling solution that lets in-home sales professionals streamline selling – from appointment scheduling to contract signatures.</p>
        <button>Learn more</button>
      </div>
      <div id={style.vendooverlay}></div>
    </div>
    <div id={style.phonebanner}>
      <img alt='banner' src={phonebanner}></img>
    </div>

    <div className={style.products}>
      <div id={style.vendooverlay}></div>
      <div className={style.productstext}>
        <h1>15+ Product Categories</h1>
        <p>From top to bottom, inside or out, Archism Vendo has the digital selling capability to handle the home improvement products you sell.</p>
      </div>

      <div className={style.categories}>
        <div id={style.item}>
            <AWNINGS className={style.svgicon} />
            <h4>AWNINGS</h4>
          </div>
        
        <div id={style.item}>
            <BATHROOM className={style.svgicon} />
            <h4>BATHROOM</h4>
          </div>
        
        <div id={style.item}>
            <CONCRETE className={style.svgicon} />
            <h4>CONCRETE</h4>
          </div>

        <div id={style.item}>
            <DECKS className={style.svgicon} />
            <h4>DECKS</h4>
          </div>

        <div id={style.item}>
            <DOORS className={style.svgicon} />
            <h4>DOORS</h4>
          </div>
      </div>

      <div className={style.categories}>
        <div id={style.item}>
            <FENCING className={style.svgicon} />
            <h4>FENCING</h4>
          </div>
        
        <div id={style.item}>
            <FLOORING className={style.svgicon} />
            <h4>FLOORING</h4>
          </div>
        
        <div id={style.item}>
            <GARAGE className={style.svgicon} />
            <h4>GARAGE</h4>
          </div>

        <div id={style.item}>
            <GUTTER className={style.svgicon} />
            <h4>GUTTER</h4>
          </div>

        <div id={style.item}>
            <HVAC className={style.svgicon} />
            <h4>HVAC</h4>
          </div>
      </div>

      <div className={style.categories}>
        <div id={style.item}>
            <KITCHEN className={style.svgicon} />
            <h4>KITCHEN</h4>
          </div>
        
        <div id={style.item}>
            <PATIO className={style.svgicon} />
            <h4>PATIO</h4>
          </div>
        
        <div id={style.item}>
            <ROOFING className={style.svgicon} />
            <h4>ROOFING</h4>
          </div>

        <div id={style.item}>
            <SIDING className={style.svgicon} />
            <h4>SIDING</h4>
          </div>

        <div id={style.item}>
            <SOLAR className={style.svgicon} />
            <h4>SOLAR</h4>
          </div>
      </div>

      <div className={style.categories2}>
        <div id={style.item}>
            <SUNROOMS className={style.svgicon} />
            <h4>SUNROOMS</h4>
          </div>
        
        <div id={style.item}>
            <WINDOWS className={style.svgicon} />
            <h4>WINDOWS</h4>
          </div>
      </div>
    </div>


    </>
  )
}
