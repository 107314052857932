import style from './Omni.module.css';
import { Link } from 'react-router-dom';
import omni from '../images/omni.png';
import React, { useState } from 'react';
import { ReactComponent as Check } from '../images/check.svg';
import charticon from '../images/charticon.png';
import bulbicon from '../images/bulbicon.png';
import settingicon from '../images/settingicon.png';
import bannner from '../images/omni3.png';

export default function Omni() {
  const [activeChannel, setActiveChannel] = useState(null);

  const handleChannelClick = (channel) => {
    setActiveChannel(channel);
  };
  return (
    <>
    <div className={style.Container}> 
      <div className={style.overlay}></div> 
      <Link id={style.btndesktop} to="/ContactUs"><button>GET A QUOTE TODAY</button></Link>
      <div className={style.Containertext}>
          <h1>Quoting Software</h1>
          <p>Archism Omni makes configuring and quoting<br/>windows and doors fast and easy. This omni-<br/>channel selling solution offers online, in-<br/>home, in-store, and dealer functionality to help you<br/>maximize your sales and reduce you tech stack</p>
          <a id={style.btnphone} to="/ContactUs"><button>GET A QUOTE TODAY</button></a>  
      </div>
    </div>
    <div id={style.btnphone}>
        <img src={omni} alt='banner'></img>
    </div>

    <div className={style.channels}>
      <div className={style.channelstext}>
        <p>Create a Best-in-Class,<br/>Seamless Experience for<br/>Your Customers Across<br/>All Your Sales Channels</p>
      </div>

    <div className={style.channels_section}>
      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('dealer')}>Dealer Sales Channel</button>
      {activeChannel === 'dealer' && (
        <div>
          <p><Check/> Manage quotes and orders with the highest rated quoting solution in the industry.</p>
          <p><Check/> Design a customized experience, from configuration to pricing to paperwork.</p>
          <p><Check/> Protect the bottom line with tools that help to ensure accurate orders every time.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('online')}>Online Sales Channel</button>
      {activeChannel === 'online' && (
        <div>
          <p><Check/> Realize the potential of the online sales channel for e-commerce and lead generation.</p>
          <p><Check/> Market products with an intuitive configuration experience on any device.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('inStore')}>In-Store Sales Channel</button>
      {activeChannel === 'inStore' && (
        <div>
          <p><Check/> Create a consistent experience across retail locations and vendor catalogs.</p>
          <p><Check/> Simplify the process of sharing product data with third-party retailers.</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('inHome')}>In-Home Sales Channel</button>
      {activeChannel === 'inHome' && (
        <div>
          <p><Check/> Maximize close rates with software tailored for the in-home sales professional.</p>
          <p><Check/> Sell your way with customized sales collateral, paperwork, pricing, and promotions.</p>
        </div>
      )}
      </div>
    </div>
    </div>

    <div className={style.Container2}>
      <div className={style.overlay2}></div>
      <h1>CPQ Software</h1>
      <h2>For The Building Industry</h2>
      <p>Archism Omni enables your customers to configure, price, and quote your unique<br/>products using industry-leading UI/UX methodologies.</p>
      <div className={style.Container2text}>
        <div id={style.textdiv}><p>Option filtering simplifies the configuration of complex products such as windows and doors.</p></div>
        <div id={style.textdiv}><p>Pricing calculations automatically factor in variables such as quantities, discounts, and customizations.</p></div>
        <div id={style.textdiv}><p>The UI is tied directly to your product data to ensure fast & accurate quoting every time.</p></div>
      </div>
    </div>

    <div className={style.Container3}>
      <div className={style.Container3text}>
        <h2>Meaningful Improvements for Your Business</h2>
        <p>Tackle some of the most common challenges that distributors, manufacturers, and retailers face.</p>
      </div>

      <div className={style.c3textbox}>
        <div id={style.boxdiv1}>
          <h3>INCREASE SALES</h3>
          <p>Provide your users with an experience they’ll love,<br/>resulting in more sales and greater customer loyalty.</p>
        </div>
        <div>
          <img src={charticon} alt='icon'></img>
        </div>
      </div>

      <div className={style.c3textbox}>
        <div id={style.boxdiv1}>
          <h3>Operate Smarter</h3>
          <p>Leverage a single catalog across multiple sales channels. Manage your<br/>pricing, products, and customer information all in one convenient place.</p>
        </div>
        <div>
          <img src={bulbicon} alt='icon'></img>
        </div>
      </div>

      <div className={style.c3textbox}>
        <div id={style.boxdiv1}>
          <h3>Manage Complexity</h3>
          <p>Access valuable insights into quotes, orders, and sales performance data. Make better,<br/>faster decisions to increase revenue.</p>
        </div>
        <div>
          <img src={settingicon} alt='icon'></img>
        </div>
      </div>
    </div>

    <div className={style.selling}>
    <div className={style.selling_section}>
      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('features')}>Platform Features</button>
      {activeChannel === 'features' && (
        <div>
          <p><Check/> Archism-managed services</p>
            <ul>
              <li>Managing the software, upgrades, releases</li>
              <li>System security</li>
              <li>Disaster recovery</li>
              <li>Application support</li>
              <li>Scalable</li>
            </ul>
          <p><Check/> Best-in-class configurator</p>
          <p><Check/> Centralized product data for po configuration</p>
            <ul>
              <li>Define the rules for how your pr presented across your custome channels</li>
              <li>Pricing</li>
              <li>Engineering accurate drawings</li>
              <li>Visualizations</li>
              <li>BOM</li>
              <li>Product and marketing document</li>
            </ul>
          <p><Check/> APIs for managing your data ar integrating with your systems</p>
          <p><Check/> Multi-lingual technology</p>
          <p><Check/> Sales and system usage analytics</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('network')}>Dealer Network<br/>Selling Features</button>
      {activeChannel === 'network' && (
        <div>
          <h4>Tools for your dealers to provide more accurate and personalized quotes to their customers with a focus on ease and speed.</h4>
          <p><Check/> Quote and order management print or email quotes & sales documents</p>
          <p><Check/> Build and maintain customer records</p>
          <p><Check/> Customer pricing management integrated to your back office systems</p>
          <p><Check/> Browser-based, device agnostic Mobile-Friendly</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('inHome')}>In-Home Selling Feature</button>
      {activeChannel === 'inHome' && (
        <div>
          <h4>Workflow tailored for in-home selling professionals</h4>
          <p><Check/> Create and manage in-home appointments</p>
          <p><Check/> Use your Favorite, internet-connected device to enjoy native iOS, native Android and browser based functionality</p>
          <p><Check/> Electronically sign quotes/sales documents</p>
          <p><Check/> Create a single, or multiple, configuration packages</p>
          <p><Check/> Schedule and configure promotional pricing</p>
          <p><Check/> Accept credit or check deposit payments</p>
          <p><Check/> Seamlessly integrate with popular CRMs</p>
          <p><Check/> Create custom attachments, proposals,and contracts</p>
          <p><Check/> See robust business intelligence to analyze performance</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('online')}>Online Selling Feature</button>
      {activeChannel === 'online' && (
        <div>
          <h4>Inspire consumers by presenting your products online</h4>
          <p><Check/> Provide guidance for product selection</p>
          <p><Check/> Consumer-friendly configuration interface</p>
          <p><Check/> Gather lead contact information Allows consumers to create and save a project</p>
          <p><Check/> Route project details directly to dealer channel</p>
          <p><Check/> Integrates to your e-commerce platform</p>
          <p><Check/> Browser-based, device agnostic Mobile-friendly</p>
        </div>
      )}
      </div>

      <div className={style.channels_menu}>
        <button onClick={() => handleChannelClick('instore')}>In-Store Selling Feature</button>
      {activeChannel === 'instore' && (
        <div>
          <p><Check/> Inegrated to your point of sale system</p>
          <p><Check/> Provide guidance for product selection</p>
          <p><Check/> Quote and order management</p>
          <p><Check/> Quote from multiple suppliers Branding standardization for catalogs</p>
          <p><Check/> Print or email quotes and sales documents</p>
          <p><Check/> Supports promotions</p>
        </div>
      )}
      </div>
    </div>

    <div className={style.sellingtext}>
        <h2>FULL-FEATURED<br/>SELLING SOLUTION</h2>
      </div>
    </div>

    <div className={style.bannner}>
      <div>
        <h2>Expertise When You Need It</h2>
        <p>Each Archism Customer is assigned a Professional Services Team to help ensure their success with Archism Omni. From implementation to post live project work, our team of industry experts is here to help you every step of the way.</p>
        <button>Know More</button>
      </div>
      <img alt='banner' src={bannner}></img>
    </div>
    </>
  )
}
