import React from 'react';
import style from './Homepage.module.css'; 
import banner1 from '../images/Image20240328162733.png';
import banner2 from '../images/Image20240404165335.png';
import { Link } from 'react-router-dom';
import photo1 from '../images/photo1.png';
import photo2 from '../images/photo2.png';
import photo3 from '../images/photo3.png';
import photo4 from '../images/photo4.png';
import photo5 from '../images/photo5.png';

export default function Homepage() {
  return (
    <>
    <div className={style.bannerContainer}> 
      <div className={style.bannertext}> 
          <h1>Software for the Building Industry</h1> 
          <Link to="/ContactUs"><button>GET A QUOTE TODAY</button></Link> 
      </div>
    </div>

    <div className={style.phonebannerContainer}> 
      <div className={style.phonebannertext}>
        <div className={style.phonebackground}></div>
        <img src={banner1}></img> 
        <div id={style.phonetext}>
          <h1>Software for the Building Industry</h1> 
          <Link to="/ContactUs"><button>GET A QUOTE TODAY</button></Link>
        </div> 
      </div>
    </div>

    <div className={style.textsection}>
        <h3>Do you have a Construction Project we can help you with?</h3>
        <Link to="/ContactUs"><button className={style.quoteButton2}>GET A QUOTE TODAY</button></Link>
    </div>

    <div className={style.myDiv}>
        <h1>Specialists In Modern Construction</h1>
        <div></div>
        <p>
          Archism exclusively serves the building industry, so we've developed our solutions with<br />your unique challenges in mind.
        </p>
    </div>

    <div className={style.myDiv3}>
        <div className={style.box} id={style.box1}> 
          <h4>DRAFTING SERVICES</h4>
          <p>Archism Drafting is a professional drafting and<br />blueprint service that relieves the pressure of<br />blueprint creation so homebuilders can save<br />time and focus on selling more.</p>
        </div>
        <div className={style.box} id={style.box2}> 
          <h4>ESTIMATING SERVICES</h4>
          <p>Archism's automated estimating software<br />saves time and eliminates errors associated<br />with doing material takeoffs manually.</p>
        </div>
        <div className={style.box} id={style.box3}> 
          <h4>QUOTING SERVICES</h4>
          <p>The building industry's leading solution for quoting<br />complex, configurable products. We offers online in-<br />home, in-store and dealer functionality to help you<br />maximize your sales and reduce your tech stack</p>
        </div>
    </div>

    <div className={style.myDiv} id={style.mysection}>
        <h1>Solutions For Every Business</h1>
        <div className={style.line} id={style.mysectionline}></div>
    </div>

    <div className={style.cointainerimg}>
    <div className={style.gallery}>
      <div className={style.items_img}><img src={photo1}/><h6>DISTRIBUTERS</h6></div>
      <div className={style.items_img}><img src={photo2}/><h6>CONTRACTORS</h6></div>
      <div className={style.items_img}><img src={photo3}/><h6>MANUFACTURERS</h6></div>
      <div className={style.items_img}><img src={photo4}/><h6>REMODELER</h6></div>
      <div className={style.items_img}><img src={photo5}/><h6>RETAILERS</h6></div>
    </div>
    </div>
    
    <div className={style.mobilecontainer}>
      <div>
        <img src={photo1}/>
        <div id={style.textdiv}>
          <h6>DISTRIBUTERS</h6>
        </div>
      </div>
      <div>
        <img src={photo2}/>
        <div id={style.textdiv}>
          <h6>CONTRACTORS</h6>
        </div>
      </div>
      <div>
        <img src={photo3}/>
        <div id={style.textdiv}>
          <h6>MANUFACTURERS</h6>
        </div>
      </div>
      <div>
        <img src={photo4}/>
        <div id={style.textdiv}>
          <h6>REMODELER</h6>
        </div>
      </div>
      <div>
        <img src={photo5}/>
        <div id={style.textdiv}>
          <h6>RETAILERS</h6>
        </div>
      </div>
    </div>
    

    

    <div className={style.bannerContainer2}>
      <img src={banner2}></img>
      <h4>Want to learn more?</h4>
      <div>
        <Link to="/ContactUs"><button>GET A QUOTE TODAY</button></Link> 
        <p>Only takes few seconds!</p>
      </div>
    </div>
  </>
  );
}
