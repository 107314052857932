import React from 'react';
import style from './Footerpage.module.css';
import logo from '../images/logo.png';
import logomobile from '../images/logomobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

export default function Footerpage() {
  return (
    <>
    <footer className={style.container}>
      <div className={style.about_us}>
       <Link to="/"><img src={logo} className={style.logodesktop}  alt="Archism"></img></Link>
       <Link to="/"><img src={logomobile} className={style.logomobile} alt="Archism"></img></Link>
       <p>Archism's software solutions help
          <br />
          organizations throughout the supply
          <br />
          chain to boost sales, reduce costs, and
          <br />
          become more competitive!
        </p>
       <div className={style.media_icons}>
        <a href="#"><FontAwesomeIcon className={style.icon} icon={faFacebook} /></a>
        <a href="https://www.youtube.com/@myarchism"><FontAwesomeIcon className={style.icon} icon={faYoutube} /></a>
        <a href="https://api.whatsapp.com/send?phone=917023864339"><FontAwesomeIcon className={style.icon} icon={faWhatsapp} /></a>
       </div>
      </div>
      <div className={style.contact_section}>
        <div className={style.links}>
        <h4>SUPPORT</h4>
        <Link to="/ContactUs">Contact Us</Link>
        <a href="#">Customer Communications</a>
        <a href="#">Archism Help Center</a>
        <a href="#">Archism Knowledge Base</a>
        </div>
        <div className={style.links}>
        <h4>COMPANY</h4>
        <Link to="/AboutUs">About Us</Link>
        <Link to="/JoinUs">Careers</Link>
        </div>
      </div>
      <div className={style.feature_section}>
        <div className={style.links}>
         <h4>SERVICES</h4>
         <a href="#"></a>
         <a href="#">Customer Experience Services</a>
         <Link to="/estimate">Estimate Services</Link>
         <Link to="/Drafting">Drafting Services</Link>
         <a href="#">Implementation Services</a>
         </div>
        <div className={style.links}>
         <h4>SOLUTION</h4>
         <a href="#">Contractor Sales App</a>
         <a href="#">ERP Software</a>
         <a href="#">Quoting Software</a>
        </div>
      </div>
    </footer>
    </>
  );
}
