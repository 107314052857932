import React from 'react';
import style from './AboutUs.module.css';
import bannerimage from '../images/aboutus2.2.png';
import image1 from '../images/aboutus3.png';
import image2 from '../images/aboutus4.png';
import image3 from '../images/aboutus5.png';
import image4 from '../images/aboutus6.jpg';
import missionimg from '../images/aboutus7.png';
import visionimg from '../images/aboutus8.png';

export default function AboutUs() {
  return (
    <>
    <div className={style.bannerContainer}>
        <h1>About Us</h1>
    </div>
    <div className={style.container}>
        {/*<div className={style.contactbanner}>
            <div id={style.line}></div>
            <div id={style.num_info}>
             <h4>CALL US TODAY</h4>
             <h2>+91-7023864339</h2>
            </div>
        </div>*/}
    </div>
    <div className={style.container2}>
        <div className={style.banner}>
            <img src={bannerimage}></img>  
            <div id={style.year_info}>
                <h6>SINCE</h6>
                <h4>2023</h4>
            </div>
        </div>
        <div className={style.aboutus_info}>
            <h6>ABOUT US</h6>
            <h2>Transforming Construction<br/>Technology</h2>
            <div>
            <p>Welcome to Archism, a pioneering technology company<br/>founded in 2023, dedicated to revolutionizing the<br/>construction industry. At Archism, we understand the<br/>intricate dynamics of the new construction and<br/>renovation markets, and our cutting-edge platform is<br/>designed to elevate both sales and operational efficiency<br/>for a diverse range of stakeholders.</p>
            </div>
            <button>Learn more</button>
        </div>
    </div>
    <div className={style.text}>
        <h4>Why Archism?</h4>
        <div></div>
    </div>
    <div className={style.container3}>
        <div id={style.image1}>
            <img src={image1}></img>
        </div>
        <div id={style.image2}>
            <img  src={image2}></img>
            <div>
            <h3>Innovation</h3>
            </div>
        </div>
        <div id={style.image3}>
            <img src={image3}></img>
            <div>
            <h3>Diverse Clientile</h3>
            </div>
        </div>
        <div id={style.image4}>
            <img src={image4}></img>
            <div>
            <h3>Customer Centric</h3>
            </div>
        </div>
    </div>
    <div className={style.container4}>
        <div className={style.banner4}>
          <div>
              <img src={missionimg}></img>
          </div>
          <div className={style.mission}>
              <h2>Who We Serve?</h2>
              <p>Our platform caters to a broad spectrum of professionals in<br/>the construction ecosystem, including renovation<br/>contractors, homebuilders, dealers, distributors, retailers, and<br/>manufacturers. Whether you're involved in creating new<br/>spaces or rejuvenating existing ones.</p>
              <button className={style.learnmore_button}>Learn more</button>
          </div>
        </div>
        <div className={style.banner4}>
          <div className={style.mission}>
              <h2>Our Mission</h2>
              <p>At Archism, Our mission is to empower businesses in the<br/>construction sector by providing innovative technological<br/>solutions. We strive to be the driving force behind<br/>increased sales, streamlined, and overall<br/>growth for our clients.</p>
              <button className={style.learnmore_button}>Learn more</button>
          </div>
          <div>
              <img src={visionimg}></img>
          </div>
        </div>
    </div>
    <div className={style.phonecontainer}>
        <div className={style.phonebanner}>
            <img src={missionimg}></img>
            <div>
              <h2>Our Mission</h2>
              <p>At Archism, Our mission is to empower businesses in the<br/>construction sector by providing innovative technological<br/>solutions. We strive to be the driving force behind<br/>increased sales, streamlined, and overall<br/>growth for our clients.</p>
              <button>Learn more</button>
          </div>
        </div>
    </div>
    <div className={style.phonecontainer}>
        <div className={style.phonebanner}>
            <img src={visionimg}></img>
            <div>
              <h2>Who We Serve?</h2>
              <p>Our platform caters to a broad spectrum of professionals in<br/>the construction ecosystem, including renovation<br/>contractors, homebuilders, dealers, distributors, retailers, and<br/>manufacturers. Whether you're involved in creating new<br/>spaces or rejuvenating existing ones.</p>
              <button>Learn more</button>
          </div>
        </div>
    </div>
    </>
  )
}
