import React from 'react';
import style from './Buildoptimize.module.css';
import banner4 from '../images/buildoptimize4.png';
import phone1 from '../images/buildoptimize1-1.png';
import phone2 from '../images/buildoptimize1-2.png';
import phone3 from '../images/buildoptimize1-3.png';
import phone4 from '../images/buildoptimize1-4.png';


export default function Buildoptimize() {
  return (
    <>
    <div className={style.Container}>
      <div className={style.Container_elements}>
        <div className={style.bannertext}>
          <h1>SOLVE BUILDING CONFLICTS<br />BEFORE THEY HAPPEN</h1>
        </div>
        <div className={style.bannertext2}>
          <p>Maximize savings with precise planning. Our BIM experts<br />streamline construction, reducing time and costs. Build<br />Optimize enhances collaboration, controls material expenses,<br />and minimizes delays. Make your next build your best one yet.</p>
        </div>
      </div>
    </div>

    <div className={style.Container2}>
      <div className={style.Container2_elements}>
        <div className={style.banner2text}>
          <h1>A UNIFIED MODEL FOR<br />TIGHTER COORDINATION</h1>
        </div>
        <div className={style.banner2text2}>
          <p>Incorporate Build Optimize BIM coordination<br />for tighter project coordination, reduced<br />design errors, more efficient material<br />management, and increased profit margins.</p>
        </div>
        <div className={style.banner2text3}>
          <p>Our technical team of residential construction<br />experts create detailed construction models<br />that speed decision making, simplify<br />changes, and enhance collaboration.</p>
        </div>
        <div className={style.mobilebanner2}>
          <div>
            <h1>A UNIFIED MODEL FOR<br />TIGHTER COORDINATION</h1>
          </div>
          <div className={style.mp1}>
            <p>Incorporate Build Optimize BIM<br />coordination for tighter project<br />coordination, reduced design errors,<br />more efficient material management,<br />and increased profit margins.</p>
          </div>
          <div className={style.mp2}>
            <p>Our technical team of residential construction<br />experts create detailed construction models<br />that speed decision making, simplify changes,<br />and enhance collaboration.</p>
          </div>
        </div>
      </div>
    </div>

    <div className={style.Container3}>
      <div className={style.Container3_elements}>
        <div id={style.box1}>
          <p>Coordinate architects, builders and trades,<br />to ensure everybody is building to the<br />same vision.</p>
        </div>
        <div id={style.box2}>
          <p>Experienced BIM coordinators ensure<br />design and pre-construction progresses<br />smoothly and sets up an accurate and<br />efficient construction process.</p>
        </div>
        <div id={style.box3}>
          <p>Advanced 3D modeling identifies<br />construction clashes and resolve mechanical<br />design conflicts before breaking ground.</p>
        </div>
        <div id={style.box4}>
          <p>Quickly and efficiently resolve questions<br />before they arise on the job site.</p>
        </div>
        <div id={style.box5}>
          <p>Unified 3D models provide visibility that helps<br />create more precise estimates, control material<br />costs, reduce change orders, avoid costly delays,<br />and enhances sales and marketing efforts.</p>
        </div>
      </div>
    </div>
    
    <div className={style.Container4}>
      <div className={style.desktop}>
        <img src={banner4}></img>
      </div>
      <div className={style.phone}>
        <div className={style.tag}>
          <p id={style.tag1}>Step 1</p>
          <p id={style.tag2}>Step 2</p>
          <p id={style.tag3}>Step 3</p>
          <p id={style.tag4}>Step 4</p>
        </div>
        <img src={phone1}></img>
        <img src={phone2}></img>
        <img src={phone3}></img>
        <img src={phone4}></img>
      </div>
    </div>
    </>
  );
}
