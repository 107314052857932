import React from 'react';
import style from './JoinUs.module.css'; 
import { Link } from 'react-router-dom';
import Vector from '../icons/Vector.png';
import icon from '../icons/icon.png';
import Vector2 from '../icons/Vector2.png';
import icon2 from '../icons/Icon2.png';

export default function JoinUs() {
  return (
    <>
    <div className={style.joinusbanner}>
      <h1>JOIN US</h1>
      <p>we're not just building careers; we're nurturing passions and empowering professionals to reach their full potential. Joining our team means becoming part of a dynamic community where innovation thrives, creativity is valued, and collaboration is encouraged.</p>
      <div>
        <Link to="/"><button id={style.button1}>Join the team</button></Link>
        <Link to="/"><button id={style.button2}>Culture</button></Link>
      </div>
    </div>

    <div className={style.container}>
      
      <div className={style.benfites}>
        <h4>BENEFITS</h4>
        <h1>Why You Should Join Our<br/>Awesome Team</h1>
        <p>At our company, every team member plays a crucial role in driving meaningful impact. Whether you're developing cutting-edge technologies, designing innovative solutions, or delivering exceptional customer experiences, your contributions will make a difference.</p>
      </div>
      
      <div className={style.grid_container}>

        <div className={style.grid_item}>
        <div id={style.icon1} className={style.icon}><img src={Vector}></img></div>
          <h2>Team Work</h2>
          <p>At our company, we value teamwork. Here, you'll have the opportunity to work with a cohesive and supportive team where everyone's input is valued, and each individual is respected for their contributions.</p>
        </div>

        <div className={style.grid_item}>
        <div id={style.icon2} className={style.icon}><img src={icon}></img></div>
          <h2>Secured Future</h2>
          <p>At Archism, we are committed to providing you with a stable and secure future. We offer job security and long-term growth opportunities, allowing you to pursue your career goals with confidence.</p>
        </div>

        <div className={style.grid_item}>
        <div id={style.icon3} className={style.icon}><img src={Vector2}></img></div>
          <h2>Learning Opportunity</h2>
          <p>Our company provides you with the chance to work with new and innovative technologies. You'll encounter new challenges and projects that will help you enhance your skills and knowledge.</p>
        </div>

        <div className={style.grid_item}>
        <div id={style.icon4} className={style.icon}><img src={icon2}></img></div>
          <h2>Upgrate Skills</h2>
          <p>We prioritize your professional development and growth. At Archism, we offer regular training sessions and skill development programs to help you advance in your career and be prepared for new opportunities.</p>
        </div>

      </div>
    </div>
    </>
  )
}
